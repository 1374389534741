













import { Vue, Prop, Component } from 'vue-property-decorator'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { analyticsSignupCompleted } from '~/services/analytics'
import { removeUserFromMailingList } from '~/api/user'

@Component({ components: { VLoadSpinner } })
export default class UnsubscribeMailingList extends Vue {
    isLoading = true
    isSuccess = false

    async removeUserFromMailingList() {
        try {
            await removeUserFromMailingList()
            this.isSuccess = true
        } finally {
            this.isLoading = false
        }
    }

    created() {
        this.removeUserFromMailingList()
    }
}
