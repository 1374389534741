var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section section--fullScreen section--flexCenter" },
    [
      _vm.isLoading && !_vm.isSuccess
        ? _c(
            "div",
            { staticClass: "container container--xs" },
            [_c("VLoadSpinner")],
            1
          )
        : !_vm.isLoading && !_vm.isSuccess
        ? _c("div", { staticClass: "container container--xs" }, [
            _c("h1", { staticClass: "textColorDanger" }, [_vm._v("Error")]),
            _vm._m(0),
          ])
        : !_vm.isLoading && _vm.isSuccess
        ? _c("div", { staticClass: "container container--xs" }, [
            _c("h2", { staticClass: "textColorSuccess" }, [
              _vm._v("Unsubscribed from newsletter!"),
            ]),
            _vm._m(1),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _vm._v("We could not verify this email, please reach us at: "),
      _c(
        "a",
        {
          staticClass: "h-textUnderline",
          attrs: { href: "mailto:support@thecharts.com" },
        },
        [_vm._v("support@thecharts.com")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _vm._v("If you made a mistake please email: "),
      _c(
        "a",
        {
          staticClass: "h-textUnderline",
          attrs: { href: "mailto:support@thecharts.com" },
        },
        [_vm._v("support@thecharts.com")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }